(function($) {
	window.updateObjectFits=function() {
		$('.object-fit--cover').each(function () {
			var $this=$(this);
			var $parent=$(this).parent();

			var px=$parent.innerWidth();
			var py=$parent.innerHeight();

			if (!$this.hasClass('object-fit--inited')) {
				$this.addClass('object-fit--inited');

				if ($this.data('objectfitaspect')===null) {
					var tx=$this.width();
					var ty=$this.height();

					$this.data('objectfitaspect', tx/ty);
				}

				$this.css({
					'position': 'absolute'
				});
			}

			if (!$parent.hasClass('object-fit--parent')) {
				$parent.addClass('object-fit--parent');

				switch ($parent.css('position')) {
					case 'relative':
					case 'absolute':
					case 'fixed':
						break;

					default:
					$parent.css({
						'position': 'relative'
					});
				}
			}

			var tf=$this.data('objectfitaspect');
			var pf=px/py;

			if (tf<=pf) {
				var nx=px;
				var ny=px/tf;
			} else {
				var nx=py*tf;
				var ny=py;
			}

			$this.css({
				'left': (px-nx)/2,
				'top': (py-ny)/2,
				'right': 'auto',
				'auto': 'auto',
				'width': nx,
				'height': ny
			});
		});
	}

	function updateResize() {
		setTimeout(updateObjectFits, 1);
	}

	$(document).ready(function() {
		$(window).resize(updateResize);
		updateResize();
	});
})(jQuery);
