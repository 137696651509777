import "./objectfit";

declare let Slider: any;
declare let google: any;

class Site {
    public static onTraining(): void {
        $(() => {
            let $maps = $('.trainings__gmap');

            $maps.each(function () {
                let $map = $(this);
                let latlng = new google.maps.LatLng($map.data('lat'), $map.data('lng'));

                let mapType = new google.maps.StyledMapType([{
                    featureType: "all",
                    elementType: "all",
                    stylers: [{
                        saturation: -100
                    }]
                }], { name:"grayscale" });

                let map = new google.maps.Map($map.get(0), {
                    center: latlng,
                    scrollwheel: false,
                    zoom: 12,
                    mapTypeId: 'grayscale'
                });

                map.mapTypes.set('grayscale', mapType);

                let ico = {
                    url: '/theme/images/map/marker.png',
                    anchor: new google.maps.Point(16, 32)
                };

                let marker = new google.maps.Marker({
                    position: latlng,
                    icon: ico,
                    map: map
                });

            });
        });
    }

    public static discover($parent?: JQuery): void {
        let $articlesmenu = $('.articles-menu', $parent);

        $('.articles-menu__item--selected', $articlesmenu).parentsUntil($articlesmenu, '.articles-menu__item').addClass('articles-menu__item--focused');
    }

    public static init(): void {
        (<any> window).Site = Site;

        $(() => {
            this.discover();
        });
    }
}

(function($) {
    function isOnFocus($el) {
        var wh = $(window).height();
        var wt = $(window).scrollTop();
        var wb = wt + wh;
        var et = $el.offset().top
        var eb = et + $el.height();

        if (et >= wt && et <= wb || eb >= wt && eb <= wb) {
            return true;
        } else {
            return false;
        }
    }

    function onViewFocus($el, callback) {
        $el.each(function() {
            var $this = $(this);
            var focused = false;

            if (isOnFocus($this)) {
                callback($this);
            } else {
                $(window).scroll(function() {
                    if (isOnFocus($this)) {
                        if (!focused) {
                            callback($this);
                        }

                        focused = true;
                    } else {
                        focused = false;
                    }
                });
            }
        });
    }

    function scrollTo(top, force?: boolean) {
        if (force) {
            $('html,body').scrollTop(top);
        } else {
            $('html,body').animate({
                scrollTop: top
            }, 900);
        }
    }

    function updateFloat() {
        var $header=$('.header');

        var top=$(window).scrollTop();
        var wh=$(window).height();

        $('body').toggleClass('float', top > 0);
        $('body').toggleClass('nofloat', top == 0);
        $('body').toggleClass('body--float-100', top > wh);
        $('body').toggleClass('body--float-ac', top > wh - 130);

        var $ac = $('.article-counters');
        var $af = $('.article-figure');

        if ($ac.length) {
            var acy = $ac.height();

            if ($ac.css('position') == 'fixed') {
                acy = 0;
            }

            $af.css({
                bottom: acy
            });
        }
        //var hcy=$header.height();

        //$('body').css('padding-top', hcy);
    };

    (<any> window).openInfo = function (url) {
        var $box = $('<div class="pobox"> \
            <div class="pobox__overlay"> \
            <div class="pobox__inner"> \
                <a class="pobox__close"></a> \
                <div class="pobox__body"></div> \
            </div> \
        </div>');

        function poboxClose() {
            $box.fadeOut(200, function () {
                $('body').removeClass('body--lock');

                $box.remove();
            });
        }

        $box.addClass('pobox--loading');
        $box.hide();

        $('body').append($box);

        $(document).click(function (e) {
            var $target = $(e.target);
            if ($target.is($('.pobox__overlay, .pobox__close'))) {
                poboxClose();
            }
        });

        $('body').addClass('body--lock');

        $box.fadeIn();

        $.get(url, function (html) {
            var $html = $(html);

            $('.pobox__body', $box).append($html);

            $box.removeClass('pobox--loading');
        });
    };

    (<any> window).updateElements = function($parent) {
        var wx = $(window).width();

        $('.lightbox, .fancybox, a[rel="lightbox"]', $parent).each(function() {
            var $el=$(this);

            (<any> $el).fancybox({
                titlePosition: 'over',
                width: $el.data('lightbox-width'),
                height: $el.data('lightbox-height'),
                type: $el.hasClass('js-lightbox--iframe') ? 'iframe' : null,

                onComplete: function() {
                    $('#fancybox-frame').attr("allowfullscreen", "allowfullscreen");
                }
            });
        });

        (<any> $('.svg-inject', $parent)).svgInject();

        $('.js-gotop', $parent).click(function(e) {
            e.preventDefault();

            scrollTo(0);
        });

        $('.js-godown', $parent).click(function(e) {
            e.preventDefault();

            var $this = $(this);
            var $header = $('.header');

            window.setTimeout(function () {
                var name = $this.data('name');;
                var $dst = $('[name="' + name + '"]');

                var hh = $header.height();
                var top = $dst.offset().top;

                scrollTo(top - hh);
            }, 1);
        });

        var $body = $('body');

        var anims = ['zoom'];

        $('.js-anim', $parent).each(function() {
            var $this = $(this);

            if (!$body.hasClass('body--loaded') && isOnFocus($this)) {
                return;
            }

            if ($body.hasClass('body--mobile') && $this.hasClass('js-anim--nomobile')) {
                return;
            }

            var i = Math.floor(anims.length * Math.random());
            var type = anims[i];

            var old_transform = $this.css('transform');
            var old_transition = $this.css('transition');
            var old_opacity = $this.css('opacity');

            $this.addClass('anim--initial anim--' + type);

            onViewFocus($this, function() {
                if (!$this.hasClass('anim--inited')) {
                    $this.addClass('anim--inited');

                    var rr = Math.random() * 400;

                    setTimeout(function() {
                        $this.css({
                            'transform': old_transform,
                            'opacity': old_opacity
                        });
                    }, 200 + rr);

                    setTimeout(function() {
                        $this.css({
                            'transition': old_transition
                        });
                    }, 200 + 800 + rr);
                }
            });
        });
    }

    $(document).ready(function() {
        function initHtml() {
            var $mainmenu=$('.mainmenu');

            $('.menubutton', $mainmenu).click(function(e) {
                e.preventDefault();

                $mainmenu.toggleClass('mainmenu--opened', !$mainmenu.hasClass('mainmenu--opened'))
                $('body').toggleClass('body--menuopened', $mainmenu.hasClass('mainmenu--opened'))
            });

            (<any> window).updateElements();
        }

        function initHome() {
            var $slider = $('.mainslider');

            function stopAll() {
                $('video', $slider).each(function() {
                    (<HTMLVideoElement> this).pause();
                });
            }

            new Slider($slider, {
                fixed: true,
                max: 1,

                onChange: function (i) {
                    var $item = $('.slider__item', $slider).eq(i);
                    var $figure = $('figure', $item);

                    var src = $item.data('movie');

                    if (src != null) {
                        stopAll();

                        if (!$item.hasClass('slider__item--movie')) {
                            $item.addClass('slider__item--movie');

                            var $video = $('<video loop muted>');
                            $video.data('objectfitaspect', 16/9);
                            $video.addClass('object-fit--cover');

                            $video.on('canplaythrough', function() {
                                $video.appendTo($figure);

                                (<any> window).updateObjectFits();

                                var video = <HTMLVideoElement> $video.get(0);
                                video.play();

                                $item.addClass('slider__item--movieinited');
                            });

                            $video.append('<source src="' + src + '" type="video/mp4">');
                        } else {
                            var $video = $('video', $item);
                            var video = <HTMLVideoElement> $video.get(0);

                            if (video) {
                                video.play();
                            }
                        }
                    }
                }
            });
        }

        function initRwd() {
            var $header = $('.header');
            $header.addClass('header--noanim');

            $(window).resize(updateFloat);
            $(window).scroll(updateFloat);

            updateFloat();

            $header.removeClass('header--noanim');
        }

        initHtml();

        if ($('.vhome').length) {
            initHome();
        }

        initRwd();
    });

    $(window).load(() => {
        $('body').addClass('body--loaded');

        setTimeout(function() {
            $('body').addClass('body--loaded1s');
            $('body').removeClass('body--loading');
            $('body').trigger('pageloaded');
        }, 1000);
    });
})(jQuery);

Site.init();
